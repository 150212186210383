import React, { Component } from "react"
import Moment from "moment"

Moment.locale("es_MX")

class Event extends Component {
  stringifyDate() {
    let date = this.props.date
    return Moment(date).format("DD · MM · YY")
  }
  getPostType() {
    let index = this.props.index
    return index % 2 === 0 ? "" : "type2"
  }
  render() {
    let event = this
    let { coverImage, title, subtitle, place, html } = event.props
    let post_type = event.getPostType()
    return (
      <div className="blog-section">
        <article className={`blog-entry ${post_type}`}>
          <div className="entry-thumb">
            <img src={coverImage} alt={title} title={title} />
          </div>
          <div className="entry-details">
            <div className="entry-title">
              <h3>
                <a href="/">
                  {title}
                  <br />
                  <b>{subtitle}</b>
                </a>
              </h3>
            </div>
            <div className="entry-body">
              <p>
                <b>{event.stringifyDate()}</b> @ <b>{place.toUpperCase()}</b>
              </p>
              <div
                className="entry-html"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
            <a
              className="type1 dt-sc-button small"
              target="_top"
              rel="noopener noreferrer"
              href="mailto:noesunagaleria@gmail.com"
            >
              Reservar
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </article>
      </div>
    )
  }
}

export default Event
