import React from "react"
import { Helmet } from "react-helmet"
import { Header, Footer } from "../components/base/"
import { Events, Contact, Slider } from "../components/sections/"
import { graphql } from "gatsby"

class Index extends React.Component {
  render() {
    let events = this.props.data.events.edges
    return (
      <div className="wrapper">
        <Helmet>
          <title>No es una galeria</title>
        </Helmet>
        <div className="inner-wrapper">
          <div id="main">
            <Header />
            <Slider />
            <Events list={events} />
            <Contact />
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

export default Index

export const allPosts = graphql`
  query LatestEvents {
    events: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            subtitle
            place
            date
            keywords
            coverImage
          }
        }
      }
    }
  }
`
