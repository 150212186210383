import React, { Component } from "react"
import Event from "../events/Event"

class Events extends Component {
  render() {
    let { list } = this.props
    let events = list.map((event, index) => {
      return (
        <Event
          key={event.node.id}
          index={index}
          html={event.node.html}
          {...event.node.frontmatter}
        />
      )
    })
    return (
      <section id="events" className="content-full-width">
        <div className="dt-sc-hr-invisible-small" />
        <div className="clear" />
        <div className="container">
          <div className="main-title">
            <h2 className="aligncenter"> EVENTOS </h2>
          </div>
        </div>
        <div className="fullwidth-section">{events}</div>
        <div className="clear" />
        <div className="dt-sc-hr-invisible-small" />
      </section>
    )
  }
}

export default Events
