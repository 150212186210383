import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import $ from "jquery"

class Newsletter extends Component {
  constructor(props) {
    super(props)
    this.state = { email: null }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange = event => {
    event.preventDefault()
    event.stopPropagation()

    this.setState({ email: event.target.value })
  }
  handleSubmit = event => {
    event.preventDefault()
    event.stopPropagation()

    let { email } = this.state
    addToMailchimp(email, { SOURCE: "WEBSITE" }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        let span_msg = `<span class="${data.result}-msg">${data.msg}</span>`
        $("#ajax_subscribe_msg").html(span_msg)
        $("#ajax_subscribe_msg").slideDown("slow")
        if (data.result.match("success") !== null)
          $(".newsletter").slideUp("slow")
      })
      .catch(() => {})
  }
  render() {
    return (
      <div className="container">
        <div className="newsletter">
          <h3>Subscribete</h3>
          <form
            method="post"
            className="mailchimp-form dt-sc-one-half column first"
            name="frmnewsletter"
            onSubmit={this.handleSubmit}
          >
            <p className="input-text">
              <input
                className="input-field"
                type="email"
                name="email"
                defaultValue=""
                onChange={this.handleChange}
                required
              />
              <label className="input-label">
                <i className="fa fa-envelope-o icon" />
                <span className="input-label-content">Correo</span>
              </label>
              <button
                type="button"
                name="submit"
                className="submit"
                value="Suscribirse"
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </p>
          </form>
          <div id="ajax_subscribe_msg" />
          <div
            className="newsletter-text animate dt-sc-one-half column"
            data-animation="stretchRight"
            data-delay="100"
          >
            <i className="fa fa-envelope-o"> </i>¡Suscribete a nuestro boletín
            aquí!
            <br />
            ¡Y conoce nuestros emocionantes eventos y ofertas!
          </div>
        </div>
      </div>
    )
  }
}

export default Newsletter
