import React from "react"
import logo from "../../images/logo@100px.png"
import $ from "jquery"

class Header extends React.Component {
  componentDidMount() {
    //create a sticky nav
    console.log("This is testing A");
    let headerH = $("#header").height()
    $(document).bind("ready scroll", function() {
      let docScroll = $(document).scrollTop()
      if ($("#header").length && docScroll >= headerH) {
        if (
          !$("#header").hasClass("header-animate") &&
          !$("#header").hasClass("nav-is-visible")
        ) {
          $("#header")
            .addClass("header-animate")
            .css({
              top: "-155px",
            })
            .stop()
            .animate(
              {
                top: 0,
              },
              500
            )
        }
      } else {
        $("#header").removeClass("header-animate")
      }
    })
  }

  render() {
    return (
      <div id="header-wrapper" className="dt-sticky-menu">
        <section id="header" className="header">
          <div className="container menu-container">
            <a className="logo" href="index.html">
              <img alt="Logo" src={logo} />
            </a>
            <span className="total-overlay-trigger" />
          </div>
        </section>
        <div className="total-overlay-sticky">
          <nav id="navigation">
            <ul className="menu type2">
              <li className="logo">
                <a href="/">
                  <img alt="Logo" src={logo} />
                </a>
              </li>
              <li className="menu-item-simple-parent disabled">
                <a title="Proximamente" href="/">
                  proximamente...
                </a>
              </li>
            </ul>
          </nav>
          <span className="total-overlay-sticky-close" />
        </div>
      </div>
    )
  }
}

export default Header
