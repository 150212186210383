import React from "react"
import { Newsletter } from "../base"

const Contact = () => {
  return (
    <section id="contacto" className="content-full-width">
      <div className="container">
        <div className="main-title">
          <h2 className="aligncenter"> CONTACTO </h2>
        </div>
      </div>
      <div className="fullwidth-section">
        <div className="contact-section">
          <div
            id="contact_map"
            className="animate"
            data-animation="fadeInRight"
            data-delay="100"
          />
          <div
            className="dt-sc-contact-info animate"
            data-animation="fadeInLeft"
            data-delay="100"
          >
            <h3>NO ES UNA GALERIA</h3>
            <div className="dt-sc-contact-details">
              <span className="fa fa-map-marker" /> Dirección: Dr José María
              Vertiz 86, Doctores, 06720 Ciudad de México, CDMX
            </div>
            <div className="dt-sc-contact-details">
              <span className="fa fa-tablet" /> Telefono: 614 156 2516
            </div>
            <div className="dt-sc-contact-details">
              <span className="fa fa-envelope" /> Email:
              <a href="mailto:noesunagaleria@gmail.com">
                &nbsp;noesunagaleria@gmail.com
              </a>
            </div>
            <ul className="type3 dt-sc-social-icons">
              <li className="dribbble">
                <a
                  href="https://www.instagram.com/noesunagaleria/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-instagram" />
                </a>
              </li>
              <li className="facebook">
                <a
                  href="https://www.facebook.com/noesunagaleria/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Newsletter />
      </div>
    </section>
  )
}

export default Contact
