import React from "react"
import $ from "jquery"

class Slider extends React.Component {
  compnentDidMount() {
    $(".parallax").each(function() {
      $(this).bind("inview", function(event, visible) {
        if (visible === true) {
          $(this).parallax("50%", 0.3)
        } else {
          $(this).css("background-position", "")
        }
      })
    })
  }
  render() {
    return (
      <div className="slider-container">
        <div className="slider fullwidth-section parallax" />
      </div>
    )
  }
}

export default Slider
