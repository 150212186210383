import React from "react"

const Footer = () => {
  return (
    <footer
      id="footer"
      className="animate"
      data-animation="fadeIn"
      data-delay="100"
    >
      <div className="container">
        <div className="copyright">
          <ul className="payment-options hidden">
            <li>
              <i className="fa fa-cc-amex" />
            </li>
            <li>
              <i className="fa fa-cc-mastercard" />
            </li>
            <li>
              <i className="fa fa-cc-visa" />
            </li>
            <li>
              <i className="fa fa-cc-discover" />
            </li>
            <li>
              <i className="fa fa-cc-paypal" />
            </li>
          </ul>
          <p>
            © 2019 <b className="text-white">NO ES UNA GALERIA</b>. Todos los
            derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
